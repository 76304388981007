import myImage from './image.PNG'; 
export function Home() {
        return (
            <>
            <div style={{ justifyContent: "center", display: "flex"}}><a href='https://apple.co/4epxcuk' style={{ 
                backgroundColor:  '#6200ea' ,
                color: 'white' ,
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
            }}>Download App</a>&nbsp;&nbsp;</div>
            <div style={{ justifyContent: "center", display: "flex"}}>
            <img src={myImage} style={{width: "auto", height: "100vh"}}/>

            </div>
            </>
        )

}
